<template>
  <div class="container my-5">
    <b-row align-content="center" align-h="center" align-v="center">
      <div class="d-flex my-5 justify-content-center">
        <img src="@/assets/logo/spinner.png" class="" alt="logo" width="20%" />
      </div>
      <h4 class="text-center mx-2 mb-3 text-primary" style="line-height: 45px">
        {{ $t("importFromChina") }}
      </h4>

      <b-button
        variant="outline-secondary"
        class="col-4 mx-auto mt-2"
        @click="whatsAppEvent"
        href="https://wa.me/971565628959?text=مرحباً ، أريد الاستفسار عن منتج جديد"
        >{{ $t("Contact Us") }} <i class="fab fa-whatsapp"> </i
      ></b-button>
    </b-row>
  </div>
</template>

<script>
export default {
     metaInfo: {
    title: "Special Order",
         meta: [
      {
        vmid: "description",
        name: "description",
        content:"إن كانت هنالك منتجات معينة غير متوفرة في الموق ع وأنتم بحاجة إلى استيرادها من الص ي، يمكنكم التو اصل معنا عبر ا لواتس آب وإرسال ك امل التفاصيل المطلوبة"      },
    ],
  },
  mounted() {
    this.$gtag.event("E_CustomOrder", { method: "Google" });
  },
  methods:{
    whatsAppEvent(){
    this.$gtag.event("E_WhatsAPP", { method: "Google" });

    }
  }
};
</script>

<style scoped>
</style>